<template>
  <print-page
    :page-number="1"
    :page-total="2"
    :barcode-text="order.fullNumber"
    :title="`KMCR wyjazdu zespołu ${companyName}`"
    class="text-black"
  >

    <b-row>

      <b-col cols="3">
        <div class="font-medium-5 font-weight-bold">
          {{ order.fullNumber }}
        </div>
        <div class="font-small-2 mt-0">
          Numer zlecenia
        </div>
        <div class="font-medium-3 mt-1">
          {{ transformDatetimeToHumanReadablePretty(order.confirmedAt) }}
        </div>
        <!-- TODO: translation -->
        <div class="font-small-2 mt-0">
          Data przyjęcia zlecenia
        </div>
      </b-col>

      <b-col
        v-if="order.pickupAddress.id"
        cols="4"
        class="text-center"
      >
        <div class="text-right">
          <h4>
            {{ order.pickupAddress.name }}
          </h4>
          <div>
            {{ order.pickupAddress.address }}
          </div>
          <div class="mb-50">
            {{ order.pickupAddress.zip }} {{ order.pickupAddress.city }}
          </div>
        </div>

      </b-col>

      <b-col
        v-if="order.pickupAddress.id && order.deliveryAddress.id"
        cols="1"
        class="text-center"
      >
        <div class="text-secondary text-center mt-2 text-4xl">
          <span class="inline-block text-black">
            <feather-icon
              v-if="isReturn"
              icon="RepeatIcon"
              size="2x"
              class="mx-auto"
            />
            <feather-icon
              v-else
              icon="ArrowRightIcon"
              size="2x"
              class="mx-auto"
            />
          </span>
        </div>

      </b-col>

      <b-col
        v-if="order.deliveryAddress.id"
        cols="4"
        class="text-center"
      >
        <div class="text-left">
          <h4>
            {{ order.deliveryAddress.name }}
          </h4>
          <div>
            {{ order.deliveryAddress.address }}
          </div>
          <div class="mb-50">
            {{ order.deliveryAddress.zip }} {{ order.deliveryAddress.city }}
          </div>
          <div>
            <i>{{ order.deliveryDetails }}</i>
          </div>
        </div>

      </b-col>

      <b-col
        cols="6"
        class="custom-print-border-right"
      >

        <rc-divider>{{ $t('order.patient-info.title') }}</rc-divider>
        <h4> {{ order.patientInfo.surname }} {{ order.patientInfo.name }} </h4>
        <div>
          <span> {{ $t(`order.patient-info.${order.patientInfo.sex}`) }},</span>
          <span class="text-lowercase"> {{ $t('order.patient-info.age') }}: </span>
          {{ order.patientInfo.age }} {{ $t('order.patient-info.years') }}
        </div>
        <div v-if="order.patientInfo.patientData.noPesel">
          {{ $t('order.patient-info.no-pesel') }}
        </div>
        <div v-else>
          {{ $t('order.patient-info.pesel') }}: {{ order.patientInfo.pesel ? order.patientInfo.pesel : $t('shared.no-data') }}
        </div>

      </b-col>

      <b-col cols="6">

        <rc-divider>{{ $t('order.label.transport-info.client-data-title') }}</rc-divider>

        <div v-if="order.private">
          <p class="mt-2 font-medium-4 text-center">
            {{ $t('order.label.private-order') }}
          </p>
        </div>
        <div v-else-if="order.customerAddress.customer.id">
          <h4 class="mt-2">
            {{ order.customerAddress.customer.name }}
          </h4>
          <div> {{ order.customerAddress.customer.addressLine1 }} </div>
          <div> {{ order.customerAddress.customer.zip }} {{ order.customerAddress.customer.city }} </div>
        </div>

      </b-col>

      <b-col cols="7">
        <!-- TODO: translation -->
        <rc-divider class="-mb-3">
          Rodzaj wyjazdu
        </rc-divider>
      </b-col>

      <b-col cols="5">
        <!-- TODO: translation -->
        <rc-divider class="-mb-3">
          Skład zespołu
        </rc-divider>
      </b-col>

      <b-col
        cols="2"
        class="text-center"
      >
        <div class="font-large-1">
          {{ order.team.teamName.name }}
        </div>
        <!-- TODO: translation -->
        <div class="font-small-2 line-height-condensed">
          Identyfikator zespołu
        </div>
      </b-col>

      <b-col
        cols="2"
        class="text-center"
      >
        <div class="font-large-1">
          {{ order.team.vehicle.sideNumber }}
        </div>
        <!-- TODO: translation -->
        <div class="font-small-2 line-height-condensed">
          Identyfikator pojazdu
        </div>
      </b-col>

      <b-col
        cols="3"
        class="text-center custom-print-border-right from-top"
      >
        <div class="font-medium-4 mt-25">
          {{ $t(`order.types.${order.type}`) }}
        </div>
        <div class="font-medium-1 mt-n25">
          {{ $t(`order.priority-signals.${order.prioritySignals}`) }}
        </div>
        <!-- TODO: translation -->
        <div class="font-small-2 line-height-condensed">
          {{ $t('order.types.title') }}
        </div>
      </b-col>

      <b-col cols="5">
        <!-- TODO: translation -->
        <dl
          v-if="order.doctorUser"
          class="row mb-0"
        >
          <dt class="col col-4 mb-0">
            {{ $t('dashboard.teams.label.doctor-user') }}:
          </dt>
          <dd class="col col-8 mb-0">
            {{ order.doctorUser.surname }} {{ order.doctorUser.name }}
          </dd>
        </dl>
        <dl class="row mb-0">
          <dt class="col col-4 mb-0">
            {{ $t('order.team.label.paramedic-user') }}:
          </dt>
          <dd class="col col-8 mb-0">
            {{ order.team.paramedicUser.surname }} {{ order.team.paramedicUser.name }}
          </dd>
        </dl>
        <dl class="row mb-0">
          <dt class="col col-4 mb-0">
            {{ $t('order.team.label.driver-user') }}:
          </dt>
          <dd class="col col-8 mb-0">
            {{ order.team.driverUser.surname }} {{ order.team.driverUser.name }}
          </dd>
        </dl>
      </b-col>

      <b-col
        :class="{'custom-print-border-right': anyValuables}"
        :cols="anyValuables ? 6 : 12"
      >

        <rc-divider>{{ $t('order.patient-info.transport-status.title') }}</rc-divider>
        <p class="text-center">
          {{ $t(`order.patient-info.transport-status.${order.patientInfo.patientData.transportStatus}`) }}
        </p>

      </b-col>

      <b-col
        v-if="anyValuables"
        cols="6"
      >
        <rc-divider>{{ $t('order.patient-info.valuables.title') }}</rc-divider>

        <span v-if="order.patientInfo.patientData.valuables.identityDocument">
          {{ $t('order.patient-info.valuables.identity-document')
          }}<span v-if="order.patientInfo.patientData.valuables.otherItems">,</span>
        </span>
        {{ order.patientInfo.patientData.valuables.otherItems }}

      </b-col>

      <b-col
        v-if="order.patientInfo.death.deathOccurred !== 'notOccurred'"
        cols="12"
      >

        <b-row>
          <b-col cols="12">
            <rc-divider>{{ $t('order.patient-info.death.death') }}</rc-divider>
          </b-col>

          <b-col cols="4">
            <p class="text-center font-medium-1">
              {{ $t(`order.patient-info.death.occurred.${order.patientInfo.death.deathOccurred}`) }}
            </p>
          </b-col>

          <b-col
            cols="4"
            class="text-center"
          >
            <div class="font-medium-2">
              {{ transformDatetimeToHumanReadablePretty(order.patientInfo.death.time) }}
            </div>
            <div class="font-small-2">
              {{ $t('order.patient-info.death.time-title') }}
            </div>
          </b-col>

          <b-col
            cols="4"
            class="text-center"
          >
            <div
              v-if="order.patientInfo.death.deathCertificateIssued"
              class="font-medium-2 text-uppercase"
            >
              {{ $t('shared.yes') }}
            </div>
            <div
              v-else
              class="font-medium-4 text-uppercase"
            >
              {{ $t('shared.no') }}
            </div>
            <div class="font-small-2">
              {{ $t('order.patient-info.death.death-certificate.issued') }}
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="6"
        class="text-center"
      >
        <rc-divider>{{ $t('order.patient-info.summary.medical-documentation.title') }}</rc-divider>
        <div
          v-if="order.patientInfo.summary.medicalDocumentationIssued"
          class="font-medium-3"
        >
          {{
            $t(`order.patient-info.summary.medical-documentation.issued.${order.patientInfo.summary.medicalDocumentationIssued}`)
          }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="6"
        class="text-center"
      >
        <rc-divider>{{ $t('order.patient-info.summary.disinfection.title') }}</rc-divider>
        <div
          v-if="order.patientInfo.summary.disinfection"
          class="font-medium-3"
        >
          {{ $t('order.patient-info.summary.disinfection.completed') }}
        </div>
        <div
          v-else
          class="font-medium-3"
        >
          {{ $t('order.patient-info.summary.disinfection.not-completed') }}
        </div>
      </b-col>

      <b-col
        cols="6"
        offset="6"
        class="mt-3"
      >

        <div class="border border-gray border-solid">
          <!-- TODO: translation -->
          <rc-divider class="pt-5">
            Pieczątka i podpis kierownika zespołu
          </rc-divider>
        </div>

      </b-col>

    </b-row>

  </print-page>
</template>
<script>
import PrintPage from '@/views/pages/print/PrintPage.vue'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import RcDivider from '@/layouts/components/rc/RcDivider.vue'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'OrderPrintPage1',
  components: {
    RcDivider, PrintPage, BCol, BRow,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    companyName() {
      return this.$store.state.appConfig.organization.info.shortName
    },
    anyValuables() {
      return this.order.patientInfo.patientData.valuables.identityDocument
        || this.order.patientInfo.patientData.valuables.otherItems
    },
    isReturn() {
      let checkpoint1 = false; let checkpoint2 = false; let
        checkpoint3 = false
      this.order.orderActivities.forEach(activity => {
        if (activity.type === 'status_change') {
          if (activity.new === 'at_pickup_point' && !checkpoint1) {
            checkpoint1 = true
          } else if (activity.new === 'at_delivery_point' && !checkpoint2) {
            checkpoint2 = true
          } else if (activity.new === 'at_pickup_point' && !checkpoint3) {
            checkpoint3 = true
          }
        }
      })

      return checkpoint1 && checkpoint2 && checkpoint3
    },
    orderTimesForPrint() {
      const statusesToPrint = [
        'on_way_to_pickup_point',
        'at_pickup_point',
        'patient_pick_up',
        'completed',
      ]

      const historyToPrint = {}

      this.order.orderActivities.forEach(activity => {
        if (activity.type === 'status_change' && statusesToPrint.includes(activity.new) && !historyToPrint[activity.new]) {
          historyToPrint[activity.new] = activity
        }
      })

      return historyToPrint
    },
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
  },
}
</script>
