<template>
  <div class="divider">
    <div class="divider-text">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
}
</script>
