<template>
  <print-page
    :page-number="2"
    :page-total="2"
    :barcode-text="order.fullNumber"
    :title="`KMCR wyjazdu zespołu ${companyName}`"
  >

    <b-row>

      <b-col
        cols="4"
        class="text-center"
      >
        <rc-divider>
          {{ $t('order.patient-info.health-condition.patient-state.title') }}
        </rc-divider>
        <div v-if="order.patientInfo.healthCondition.patientState">
          {{
            $t(`order.patient-info.health-condition.patient-state.${order.patientInfo.healthCondition.patientState}`)
          }}
        </div>
        <div v-else>
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="4"
        class="text-center"
      >
        <rc-divider>
          {{ $t('order.patient-info.health-condition.awareness.title') }}
        </rc-divider>
        <div v-if="order.patientInfo.healthCondition.awareness">
          {{ order.patientInfo.healthCondition.awareness }}
        </div>
        <div v-else>
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="4"
        class="text-center"
      >
        <rc-divider>
          {{ $t('order.patient-info.health-condition.psychomotor-assessment.title') }}
        </rc-divider>
        <div v-if="order.patientInfo.healthCondition.psychomotorAssessment">
          {{
            $t(`order.patient-info.health-condition.psychomotor-assessment.${order.patientInfo.healthCondition.psychomotorAssessment}`)
          }}
        </div>
        <div v-else>
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="3"
        class="text-center"
      >
        <rc-divider>
          {{ $t('order.patient-info.health-condition.pressure.title') }}
        </rc-divider>
        <div
          v-if="order.patientInfo.healthCondition.pressure"
          class="font-medium-4"
        >
          {{ order.patientInfo.healthCondition.pressure }} mm/Hg
        </div>
        <div v-else>
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="3"
        class="text-center custom-print-border-right"
      >
        <rc-divider>
          {{ $t('order.patient-info.health-condition.pulse.title') }}
        </rc-divider>
        <div v-if="order.patientInfo.healthCondition.pulse.value">
          <span class="font-medium-4">{{ order.patientInfo.healthCondition.pulse.value }} /&nbsp;min</span>
          <span
            v-if="order.patientInfo.healthCondition.pulse.regular"
            class="lowercase font-small-3"
          >
            <br>({{ $t('order.patient-info.health-condition.pulse.regular') }})
          </span>
          <span
            v-else
            class="lowercase font-small-3"
          >
            <br>({{ $t('order.patient-info.health-condition.pulse.non-regular') }})
          </span>
        </div>
        <div v-else>
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col cols="6">

        <rc-divider>
          {{ $t('order.patient-info.health-condition.glasgow-coma-scale.title') }}
        </rc-divider>
        <div v-if="isGlasgowScale">
          <div class="font-small-1 mt-n50">
            <dl class="row my-0">
              <dt class="col-6 text-right my-0">
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.eye-response.title') }}:
              </dt>
              <dd class="col-6 my-0">
                {{
                  $t(`order.patient-info.health-condition.glasgow-coma-scale.eye-response.${order.patientInfo.healthCondition.glasgowComaScale.eyeResponse}`)
                }}
                ({{ order.patientInfo.healthCondition.glasgowComaScale.eyeResponse }})
              </dd>
            </dl>
            <dl class="row my-0">
              <dt class="col-6 text-right my-0">
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.verbal-response.title') }}:
              </dt>
              <dd class="col-6 my-0">
                {{
                  $t(`order.patient-info.health-condition.glasgow-coma-scale.verbal-response.${order.patientInfo.healthCondition.glasgowComaScale.verbalResponse}`)
                }}
                ({{ order.patientInfo.healthCondition.glasgowComaScale.verbalResponse }})
              </dd>
            </dl>
            <dl class="row my-0">
              <dt class="col-6 text-right my-0">
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.motor-response.title') }}:
              </dt>
              <dd class="col-6 my-0">
                {{
                  $t(`order.patient-info.health-condition.glasgow-coma-scale.motor-response.${order.patientInfo.healthCondition.glasgowComaScale.motorResponse}`)
                }}
                ({{ order.patientInfo.healthCondition.glasgowComaScale.motorResponse }})
              </dd>
            </dl>
          </div>
          <dl class="row mb-0 mt-50">
            <dt class="col-6 text-right my-0">
              {{ $t('order.patient-info.health-condition.glasgow-coma-scale.sum') }}:
            </dt>
            <dd class="col-6 my-0">
              {{
                order.patientInfo.healthCondition.glasgowComaScale.eyeResponse
                  + order.patientInfo.healthCondition.glasgowComaScale.verbalResponse
                  + order.patientInfo.healthCondition.glasgowComaScale.motorResponse
              }}
            </dd>
          </dl>
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>

      </b-col>

      <b-col
        cols="7"
        class="text-center"
      >
        <rc-divider>
          {{ $t('order.patient-info.health-condition.respiratory-system.title') }}
        </rc-divider>
      </b-col>

      <b-col
        cols="5"
        class="text-center"
      >
        <rc-divider>{{
          $t('order.patient-info.health-condition.respiratory-system.respiratory-sounds')
        }}
        </rc-divider>
      </b-col>

      <b-col
        cols="2"
        class="text-center"
      >
        <div v-if="order.patientInfo.healthCondition.respiratorySystem.respiratoryRate">
          <div class="font-medium-5">
            {{ order.patientInfo.healthCondition.respiratorySystem.respiratoryRate }} /&nbsp;min.
          </div>
          <small>{{ $t('order.patient-info.health-condition.respiratory-system.respiratory-rate') }}</small>
        </div>
        <div v-else>
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="2"
        class="text-center"
      >
        <div v-if="order.patientInfo.healthCondition.respiratorySystem.saturation">
          <div class="font-medium-5">
            {{ order.patientInfo.healthCondition.respiratorySystem.saturation }}%
          </div>
          <small>{{ $t('order.patient-info.health-condition.respiratory-system.saturation') }}</small>
        </div>
        <div v-else>
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="3"
        class="text-center custom-print-border-right from-top"
      >
        <div v-if="!empty(order.patientInfo.healthCondition.respiratorySystem.respiratoryFailure)">
          <div class="font-medium-3">
            <span
              v-for="(failure, key) in order.patientInfo.healthCondition.respiratorySystem.respiratoryFailure"
              :key="key"
              class="lowercase"
            >
              {{
                $t(`order.patient-info.health-condition.respiratory-system.${failure}`)
              }}<span
                v-if="key < order.patientInfo.healthCondition.respiratorySystem.respiratoryFailure.length - 1"
              >, </span>
            </span>
          </div>
          <small>{{ $t('order.patient-info.health-condition.respiratory-system.respiratory-failure') }}</small>
        </div>
        <div v-else>
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col cols="5">

        <div
          v-if="!empty(order.patientInfo.healthCondition.respiratorySystem.left) && !empty(order.patientInfo.healthCondition.respiratorySystem.left)"
        >
          <div class="row text-bold">
            <div class="col-6 text-right">
              <u>{{ $t('order.patient-info.health-condition.respiratory-system.left-lung') }}</u>
            </div>
            <div class="col-6 text-left">
              <u>{{ $t('order.patient-info.health-condition.respiratory-system.right-lung') }}</u>
            </div>
          </div>

          <div class="row mb-0">
            <div class="col-6 text-right">
              <div v-if="!empty(order.patientInfo.healthCondition.respiratorySystem.left)">
                <span
                  v-for="(lung, key) in order.patientInfo.healthCondition.respiratorySystem.left"
                  :key="key"
                  class="lowercase"
                >
                  {{
                    $t(`order.patient-info.health-condition.respiratory-system.${lung}`)
                  }}<span v-if="key < order.patientInfo.healthCondition.respiratorySystem.left.length - 1">, </span>
                </span>
              </div>
            </div>
            <div class="col-6 text-left">
              <div v-if="!empty(order.patientInfo.healthCondition.respiratorySystem.right)">
                <span
                  v-for="(lung, key) in order.patientInfo.healthCondition.respiratorySystem.right"
                  :key="key"
                  class="lowercase"
                >
                  {{
                    $t(`order.patient-info.health-condition.respiratory-system.${lung}`)
                  }}<span v-if="key < order.patientInfo.healthCondition.respiratorySystem.right.length - 1">, </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col cols="8">
        <rc-divider>
          {{ $t('order.patient-info.health-condition.pupils.title') }}
        </rc-divider>
      </b-col>

      <b-col cols="4">
        <rc-divider>
          {{ $t('order.patient-info.health-condition.paresis.title') }}
        </rc-divider>
      </b-col>

      <b-col cols="4">
        <div
          v-if="!empty(order.patientInfo.healthCondition.pupils.reactionToLight.left)
            && !empty(order.patientInfo.healthCondition.pupils.reactionToLight.left)"
        >
          <b-row>
            <b-col
              cols="6"
              class="text-right bold"
            >
              <u>{{ $t('order.patient-info.health-condition.pupils.left') }}</u>
            </b-col>
            <b-col cols="6">
              <u>{{ $t('order.patient-info.health-condition.pupils.right') }}</u>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="6"
              class="text-right"
            >
              {{
                $t(`order.patient-info.health-condition.pupils.reaction-to-light.${order.patientInfo.healthCondition.pupils.reactionToLight.left}`)
              }}
            </b-col>
            <b-col cols="6">
              {{
                $t(`order.patient-info.health-condition.pupils.reaction-to-light.${order.patientInfo.healthCondition.pupils.reactionToLight.right}`)
              }}
            </b-col>
          </b-row>
          <small class="d-block text-center">{{
            $t('order.patient-info.health-condition.pupils.reaction-to-light.title')
          }}</small>
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="4"
        class="custom-print-border-right from-top"
      >

        <div
          v-if="!empty(order.patientInfo.healthCondition.pupils.width.left)
            && !empty(order.patientInfo.healthCondition.pupils.width.left)"
        >
          <b-row>
            <b-col
              cols="6"
              class="text-right bold"
            >
              <u>{{ $t('order.patient-info.health-condition.pupils.left') }}</u>
            </b-col>
            <b-col cols="6">
              <u>{{ $t('order.patient-info.health-condition.pupils.right') }}</u>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="6"
              class="text-right"
            >
              {{
                $t(`order.patient-info.health-condition.pupils.width.${order.patientInfo.healthCondition.pupils.width.left}`)
              }}
            </b-col>
            <b-col cols="6">
              {{
                $t(`order.patient-info.health-condition.pupils.width.${order.patientInfo.healthCondition.pupils.width.right}`)
              }}
            </b-col>
          </b-row>

          <small class="d-block text-center">{{
            $t('order.patient-info.health-condition.pupils.width.title')
          }}</small>
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col cols="4">

        <ul
          v-if="isParesis"
          class="list-style-circle pl-2"
        >
          <li
            v-if="order.patientInfo.healthCondition.paresis.upperLimb.left"
            class="text-lowercase"
          >
            {{ $t('order.patient-info.health-condition.paresis.upper-limb') }}
            {{ $t('order.patient-info.health-condition.paresis.left') }}
          </li>
          <li
            v-if="order.patientInfo.healthCondition.paresis.upperLimb.right"
            class="text-lowercase"
          >
            {{ $t('order.patient-info.health-condition.paresis.upper-limb') }}
            {{ $t('order.patient-info.health-condition.paresis.right') }}
          </li>
          <li
            v-if="order.patientInfo.healthCondition.paresis.lowerLimb.left"
            class="text-lowercase"
          >
            {{ $t('order.patient-info.health-condition.paresis.lower-limb') }}
            {{ $t('order.patient-info.health-condition.paresis.left') }}
          </li>
          <li
            v-if="order.patientInfo.healthCondition.paresis.lowerLimb.right"
            class="text-lowercase"
          >
            {{ $t('order.patient-info.health-condition.paresis.lower-limb') }}
            {{ $t('order.patient-info.health-condition.paresis.right') }}
          </li>
        </ul>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>

      </b-col>

      <b-col
        cols="4"
        class="custom-print-border-right"
      >
        <rc-divider>
          {{ $t('order.patient-info.health-condition.ekg.title') }}
        </rc-divider>

        <div
          v-if="!empty(order.patientInfo.healthCondition.ekg.list)"
          class="text-center"
        >
          <span>
            <span
              v-for="(ekg, key) in order.patientInfo.healthCondition.ekg.list"
              :key="key"
            >
              {{
                $t(`order.patient-info.health-condition.ekg.${ekg}`)
              }}<span
                v-if="key < order.patientInfo.healthCondition.ekg.list.length - 1 || order.patientInfo.healthCondition.ekg.other"
              >, </span>
            </span>
          </span>
          {{ order.patientInfo.healthCondition.ekg.other }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="4"
        class="custom-print-border-right"
      >
        <rc-divider>
          {{ $t('order.patient-info.health-condition.intoxication.title') }}
        </rc-divider>

        <div
          v-if="!empty(order.patientInfo.healthCondition.intoxication)"
          class="text-center"
        >
          <span
            v-for="(intoxication, key) in order.patientInfo.healthCondition.intoxication"
            :key="key"
            class="lowercase"
          >
            {{
              $t(`order.patient-info.health-condition.intoxication.${intoxication}`)
            }}<span v-if="key < order.patientInfo.healthCondition.intoxication.length - 1">, </span>
          </span>
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col cols="4">
        <rc-divider>
          {{ $t('order.patient-info.health-condition.other.title') }}
        </rc-divider>

        <div
          v-if="!empty(order.patientInfo.healthCondition.other)"
          class="text-center"
        >
          <span
            v-for="(other, key) in order.patientInfo.healthCondition.other"
            :key="key"
            class="lowercase"
          >
            {{
              $t(`order.patient-info.health-condition.other.${other}`)
            }}<span v-if="key < order.patientInfo.healthCondition.other.length - 1">, </span>
          </span>
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        v-if="order.patientInfo.diagnosis.diagnosis.length || !empty(order.patientInfo.diagnosis.description)"
        cols="12"
      >

        <div v-if="order.patientInfo.diagnosis.diagnosis.length">
          <rc-divider class="mb-25">
            {{ $t('order.view.tabs.patient-info.diagnosis.diagnosis.title') }}
          </rc-divider>

          <div
            v-for="(diagnose, key) in order.patientInfo.diagnosis.diagnosis"
            :key="key"
            class="text-left font-small-3"
          >
            <span>
              <strong>{{ diagnose.subCategoryCode }}</strong>: {{ diagnose.subCategory }}
            </span>
            <span>
              <small> {{ diagnose.level1 }} </small>
              <small> {{ diagnose.level2 }} </small>
              <small> {{ diagnose.level3 }} </small>
            </span>
          </div>
        </div>

        <div v-if="!empty(order.patientInfo.diagnosis.description)">
          <rc-divider class="mb-25">
            {{ $t('order.view.tabs.patient-info.diagnosis.description.title') }}
          </rc-divider>

          <div class="text-left font-small-3">
            {{ order.patientInfo.diagnosis.description }}
          </div>
        </div>

      </b-col>

      <b-col
        v-if="!empty(order.patientInfo.providedHealthServices.services)"
        cols="12"
      >

        <rc-divider class="mb-25">
          {{ $t('order.view.tabs.patient-info.provided-health-services.title') }}
        </rc-divider>

        <b-table
          :items="order.patientInfo.providedHealthServices.services"
          :fields="tableColumns"
          small
          borderless
          head-variant="light"
        >

          <!-- Column: time -->
          <template #cell(teamEndedAt)="data">
            {{ transformDatetimeToHumanReadablePretty(data.time) }}
          </template>
        </b-table>

      </b-col>

      <b-col cols="6">
        <rc-divider class="mb-25">
          {{ $t('order.view.tabs.activities.title') }}
        </rc-divider>

        <div
          v-if="!empty(order.patientInfo.providedHealthServices.activities)"
          class="text-justify"
        >
          <span v-if="!empty(order.patientInfo.providedHealthServices.activities)">
            <span
              v-for="(activity, key) in order.patientInfo.providedHealthServices.activities"
              :key="key"
            >
              {{ $t(`order.patient-info.provided-health-services.activities.${activity}`) }}<span
                v-if="key < order.patientInfo.providedHealthServices.activities.length - 1 || order.patientInfo.providedHealthServices.otherActivities"
              >, </span>
            </span>
          </span>
          {{ order.patientInfo.providedHealthServices.otherActivities }}
        </div>
        <div
          v-else
          class="text-center"
        >
          {{ $t('shared.no-data') }}
        </div>
      </b-col>

      <b-col
        cols="6"
        class="mt-2"
      >

        <div class="border border-gray border-solid">
          <!-- TODO: translation -->
          <rc-divider class="pt-5">
            Pieczątka i podpis kierownika zespołu
          </rc-divider>
        </div>
      </b-col>

    </b-row>

  </print-page>
</template>
<script>
import PrintPage from '@/views/pages/print/PrintPage.vue'
import RcDivider from '@/layouts/components/rc/RcDivider.vue'
import { transformDatetimeToHumanReadablePretty, empty } from '@/helpers/helpers'
import { BCol, BRow, BTable } from 'bootstrap-vue'

export default {
  name: 'OrderPrintPage2',
  components: {
    PrintPage,
    RcDivider,
    BCol,
    BRow,
    BTable,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [
        {
          label: this.$t('order.patient-info.provided-health-services.services.name'),
          key: 'name',
          sortable: false,
          class: 'bg-white font-small-3 py-25 text-black',
        },
        {
          label: this.$t('order.patient-info.provided-health-services.services.dose'),
          key: 'dose',
          sortable: false,
          class: 'bg-white font-small-3 py-25 text-black',
        },
        {
          label: this.$t('order.patient-info.provided-health-services.services.way'),
          key: 'way',
          sortable: false,
          class: 'bg-white font-small-3 py-25 text-black',
        },
        {
          label: this.$t('order.patient-info.provided-health-services.services.time'),
          key: 'time',
          sortable: false,
          class: 'bg-white font-small-3 py-25 text-black',
        },
      ],
    }
  },
  computed: {
    companyName() {
      return this.$store.state.appConfig.organization.info.shortName
    },
    isGlasgowScale() {
      return this.order.patientInfo.healthCondition.glasgowComaScale.eyeResponse
          && this.order.patientInfo.healthCondition.glasgowComaScale.verbalResponse
          && this.order.patientInfo.healthCondition.glasgowComaScale.motorResponse
    },
    isParesis() {
      return this.order.patientInfo.healthCondition.paresis.upperLimb.left
          || this.order.patientInfo.healthCondition.paresis.upperLimb.right
          || this.order.patientInfo.healthCondition.paresis.lowerLimb.left
          || this.order.patientInfo.healthCondition.paresis.lowerLimb.right
    },
    // services() {
    //   const services = []
    //   this.order.patientInfo.providedHealthServices.services.forEach(service => {
    //     services.push([
    //
    //     ]);
    //   })
    // }
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    empty,
  },
}
</script>

<style lang="scss">
.document-stamp {
  margin-top: 7px;
  min-height: 30mm;
  position: relative;

  > div {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.divider {
  .divider-text:before, .divider-text:after {
    border-top-color: #999 !important;
  }
}
</style>
