<template>
  <div
    v-if="!loading"
    id="order-print"
    class="pb-1"
  >

    <order-print-page1 :order="order" />

    <order-print-page2 :order="order" />

  </div>
</template>

<script>
import { cloneNested, parseRequestError, transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import OrderPrintPage1 from '@/views/orders/OrderPrint/OrderPrintPage1.vue'
import OrderPrintPage2 from '@/views/orders/OrderPrint/OrderPrintPage2.vue'

export default {
  components: {
    OrderPrintPage1,
    OrderPrintPage2,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    order() {
      return this.$store.getters['order/getOrderData']
    },
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
  },
  created() {
    this.initOrderData(this.$route.params.id)
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    initOrderData(orderId) {
      this.$store.dispatch('order/fetchOrderById', orderId)
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
